.caucus-logo {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

.caucus-logo img {
  max-height: 80%;
  max-width: 80%;
  height: auto;
}

.caucus-template {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 90px 10px;
  background-color: #f9f9f9;
  margin: 0;
}

.caucus-template-text {
  flex: 1;
  padding: 0 20px;
}

.caucus-template-text h1 {
  font-size: 2rem;
  color: #000;
  margin-bottom: 15px;
}

.caucus-template-text h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
}

.caucus-template-text p {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
  font-weight: bold;
}

.caucus-template-img {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.caucus-template-img img {
  width: 100%;
  max-width: 500px;
  height: auto;
}

@media screen and (max-width: 768px) {
  .caucus-template {
    flex-direction: column;
    padding-right: 50px;
  }

  .caucus-template-text,
  .caucus-template-img {
    flex: none;
    width: 100%;
  }

  .caucus-template-img {
    margin-top: 20px;
  }
}
