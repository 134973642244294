.contact {
  background-color: white;
  margin: 0;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;
}

form {
  display: inline-block;
  padding: 20px;
  border: 2px solid #45698c;
  border-radius: 10px;
  background-image: url("/public/fmbackground.png");
  background-position: center;
  width: 50%;
  max-width: 400px;
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #707070);
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

form li + li {
  margin-top: 1em;
}

label {
  display: flex;
  min-width: 90px;
  text-align: right;
  padding-bottom: 5px;
  color: white;
}

input,
textarea {
  font: 1em sans-serif;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: 3px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

input:focus,
textarea:focus {
  border-color: #000;
}

textarea {
  vertical-align: top;
  height: 5em;
}

.button {
  text-align: center;
}

button {
  margin-left: 0;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  border: none;
  color: black;
  background-color: white;
}

.contactform-send-message {
  width: 50%;
  max-width: 400px;
  padding: 20px;
  background-image: url("/public/fmbackground.png");
  background-position: center;
  border: 2px solid #45698c;
  border-radius: 10px;
  color: white;
  text-decoration: none;
  text-align: center;
  font-size: xx-large;
  font-weight: bolder;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-shadow: 1px 1px 1px rgb(0, 0, 0);
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #707070);
}

.success,
.error {
  text-align: center;
  padding: 12px 20px;
  margin: 20px auto;
  border-radius: 4px;
  max-width: 400px;
  font-weight: bold;
  color: white;
}
