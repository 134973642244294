:root {
  background-image: url("../public/fmbackground.png");
  background-size: cover;
  background-position: center;
  color: white;
  font-family: Avenir Next, Futura;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
