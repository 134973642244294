.team-logo {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}

.team-logo img {
  max-height: 80%;
  max-width: 80%;
  height: auto;
}

.team-template {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 90px 90px 90px 0;
  background-color: #f9f9f9;
  margin: 0;
}

.team-template-img {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.team-template-img img {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 50%;
  /* border: 10px solid #45698c; */
}

.team-template-img h1 {
  font-size: 2rem;
  color: #000;
  height: fit-content;
  margin: 0;
  padding-top: 10px;
}

.team-template-img h2 {
  font-size: 1.5rem;
  color: #333;
  font-weight: bold;
  text-align: left;
  height: fit-content;
  margin: 0;
  padding-bottom: 30px;
}

.team-template-text {
  flex: 1;
}

.team-template-text p {
  font-size: 0.8rem;
  color: #666;
  line-height: 1.6;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .team-template {
    flex-direction: column;
    padding: 30px 100px 30px 100px;
  }

  .team-template-text,
  .team-template-img {
    flex: none;
    width: 100%;
    padding: 0;
  }

  .team-template img {
    margin-top: 50px;
  }
}
