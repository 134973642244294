header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 80px;
  background-image: url("/public/fmbackground.png");
  background-size: cover;
  background-position: center;
  border-bottom: 3px solid white;
}

@media (max-width: 850px) {
  .navbar {
    display: none;
  }
}

@media (min-width: 850px) {
  .toggle {
    display: none;
  }
}
