.navbar {
  display: block;
}

.desktop-nav {
  display: none;
  padding-right: 60px;
}

.desktop-nav ul {
  display: flex;
  gap: 1.5rem;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.desktop-nav li {
  position: relative;
}

.desktop-nav li:hover {
  text-decoration: none;
}

.desktop-nav a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-shadow: 1px 1px 1px black;
}

@media (min-width: 850px) {
  .desktop-nav {
    display: block;
  }
}

#navbar-electoral-college {
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  text-shadow: 1px 1px 1px black;
  display: flexbox;
  justify-content: space-between;
  transition: text-decoration 0.3s ease;
}

/* Dropdown Content */
.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  padding-top: 25px;
  margin-top: 5px;
  background-image: url("/public/fmbackground.png");
  background-size: cover;
  background-position: center;
  padding: 10px;
  border: 3px solid white;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: max-content;
  white-space: nowrap;
}

.dropdown-content::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 25px;
  background: transparent;
}

.dropdown-content a {
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-decoration: none;
  padding: 2px 5px;
  display: block;
  transition: background-color 0.3s ease;
  text-align: left;
  border: 2px solid transparent;
}

.dropdown-content a:hover {
  background-color: #45698c;
  border: 2px solid white;
  border-radius: 5px;
}

#navbar-electoral-college:hover + .dropdown-content,
.dropdown-content:hover {
  display: block;
}

#navbar-electoral-college {
  cursor: pointer;
}

.desktop-nav ul {
  display: flex;
  gap: 1rem;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.desktop-nav a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 14px;
  text-shadow: 1px 1px 1px black;
}

.desktop-nav li:nth-child(1) a:hover,
.desktop-nav li:nth-child(3) a:hover,
.desktop-nav li:nth-child(4) a:hover,
.desktop-nav li:nth-child(5) a:hover {
  background-color: #45698c;
  border: 2px solid white;
  border-radius: 5px;
}

.desktop-nav li:nth-child(1) a,
.desktop-nav li:nth-child(3) a,
.desktop-nav li:nth-child(4) a,
.desktop-nav li:nth-child(5) a {
  padding: 2px 5px;
  display: block;
  border: 2px solid transparent;
  border-radius: 5px;
}

.first-word,
.second-word {
  display: inline;
}

@media (max-width: 1000px) {
  .first-word {
    display: block;
  }

  .second-word {
    display: block;
  }

  .desktop-nav li {
    text-align: center;
  }
}
