.mobile-nav {
  display: block;
  padding-right: 20px;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative;
}

.hamburger span {
  width: 100%;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease;
  position: absolute;
  border-radius: 2px;
}

.hamburger span:nth-child(1) {
  top: 0;
}

.hamburger span:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.hamburger span:nth-child(3) {
  bottom: 0;
}

.hamburger.open span:nth-child(1) {
  transform: rotate(45deg);
  top: 50%;
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 50%;
}

.mobile-menu {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("/public/fmbackground.png");
  background-size: cover;
  background-position: center;
  padding: 1rem;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  border-top: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-menu.open {
  transform: translateX(0);
  z-index: 101;
}

.mobile-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobile-menu li {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-menu a {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 20px;
  display: inline-block;
  text-align: center;
  text-shadow: 1px 1px 1px black;
}

@media (min-width: 850px) {
  .mobile-nav {
    display: none;
  }
}
